/*!

=========================================================
* Argon Dashboard Material-UI - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-material-ui/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "argon-dashboard/custom/variables";

// React Differences
@import "react/react-differences";
.jodit-toolbar-editor-collection_mode_vertical .jodit-toolbar-button__button {
  max-height: 28px !important;
  min-height: 20px !important;
}
.jodit-toolbar-button_variant_initial {
  min-height: 24px !important;
}

.react-datepicker-popper {
  z-index: 1101 !important;
}
.react-datepicker__week-number {
  font-weight: bold;
  color: #0c0c0c !important;
}
.react-datepicker__input-container input {
  width: 100% !important;
  position: relative;
  border-radius: 4px;
  display: inline-flex;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: Open Sans,sans-serif;
  font-weight: 400;
  line-height: 2em !important;
  padding-left: 14px;
  border: 1px solid lightgrey !important;
  background-color: #f7f7f7 !important;
}
.react-datepicker__day:nth-of-type(7),
.react-datepicker__day:nth-of-type(8),
.react-datepicker__day-name:nth-of-type(7),
.react-datepicker__day-name:nth-of-type(8) {
  display: none !important;
}